import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="alt">
        <section id="one">
            <div className="inner">
                <h1>Success!</h1>
                <p>Thank you for contacting us, we'll be in touch very soon.</p>
                <a className="button next" href="./">Back home</a>
            </div>
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
